<template>
  <div
    ref="wysiwyg"
    :class="styleConfig"
    v-html="html"
  />
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
defineProps<{
  html: string;
  styleConfig: Record<string, boolean>;
}>();

const wysiwyg = ref(null);
onMounted(() => {
  if (wysiwyg.value) {
    const pTags = wysiwyg.value.querySelectorAll('p') as HTMLElement[];
    pTags.forEach((tag) => {
      if (
        tag.getElementsByTagName('img').length > 0 ||
        tag.getElementsByTagName('video').length > 0 ||
        tag.getElementsByTagName('iframe').length > 0
      ) {
        tag.replaceWith(...tag.childNodes);
      }
    });
  }
});
</script>
